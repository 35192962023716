import { useRoutes } from 'react-router-dom';

import {
  CustomerGlobal,
  MediaQueryProvider,
  Modals,
  SkipLinks,
  initAlgoliaInsights,
  setPartnerHostedConfig,
  useScrollToApp,
} from '@jane/monolith/boost';
import { config } from '@jane/shared/config';
import { useEcommApp } from '@jane/shared/providers';
import {
  getRuntimeConfig,
  useRuntimeConfig,
} from '@jane/shared/runtime-config';
import {
  initCrmMixpanel,
  initMixpanel,
  initTrackJS,
  loadTimezoneData,
  registerErrorMetadata,
} from '@jane/shared/util';

import { buildBoostForAllRoutes } from './app/boost-for-all-routes';
import { buildRoutes } from './app/routes';
import ShadowRoot from './shadow-root';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let __webpack_public_path__: string;

export const maybeSetPublicPath = (deployPublicPathSuffix = '') => {
  const runtimeConfig = getRuntimeConfig();

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!runtimeConfig.partnerHostedPath) {
    console.log(
      'setting __webpack_public_path__ to',
      `${config.deployPublicPath}${deployPublicPathSuffix}`
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = `${config.deployPublicPath}${deployPublicPathSuffix}`;
  }
};

maybeSetPublicPath();
initMixpanel(config.mixpanelCustomerToken, {
  userSessionHost: config.mixpanelUserSessionApiHost,
  useLocalStorage: true,
});
initCrmMixpanel(config.mixpanelCrmToken);
initTrackJS();
loadTimezoneData();
initAlgoliaInsights();

const App = () => {
  const { appMode } = useEcommApp();
  registerErrorMetadata('appMode', appMode);
  useScrollToApp('shadow-host');

  const { isPartnerHosted, partnerHostedPath, isBoostForAll } =
    useRuntimeConfig();

  const routes = isBoostForAll
    ? buildBoostForAllRoutes()
    : buildRoutes(isPartnerHosted, partnerHostedPath);

  const routing = useRoutes(routes);

  setPartnerHostedConfig({ isBoostForAll, isPartnerHosted, partnerHostedPath });

  if (!isPartnerHosted) return <div>Partner Config Not Found!</div>;

  return (
    <ShadowRoot>
      <CustomerGlobal />
      <MediaQueryProvider>
        <SkipLinks />
        <Modals />
        <div style={{ minHeight: '100vh' }}>{routing}</div>
      </MediaQueryProvider>
    </ShadowRoot>
  );
};

export default App;
